import { Card, CardContent } from "../../../../@/components/ui/card";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../@/components/ui/table";
import { Tabs, TabsContent } from "../../../../@/components/ui/tabs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { fetchOrderList, setSelectedOrderId } from "store/OrdersSlice";
import { fetchLeadList } from "store/leadTemplate";
import { ScrollArea } from "../../../../@/components/ui/scroll-area";
import NoDataComponent from "./orderTableComponents/NoOrderDataComponent";
import TablePaginationComponent from "./orderTableComponents/OrderTablePaginationComponent";
import OrderTableRowComponent from "./orderTableComponents/OrderTableRowComponent";
import OrderHeaderComponent from "./orderTableComponents/OrderHeaderComponent";

export default function OrderTable({ preserveState = false }) {
  const dispatch = useDispatch<AppDispatch>();
  const ordersList = useSelector((state: RootState) => state.ordersList.orders);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const orderStatus = useSelector(
    (state: RootState) => state.ordersList.status
  );
  const [orderTime, setOrderTime] = useState(() => 
    preserveState ? localStorage.getItem('orderTime') || 'all' : 'all'
  );
  const [selectedStatus, setSelectedStatus] = useState(() =>
    preserveState ? localStorage.getItem('selectedStatus') || 'all' : 'all'
  );
  const [currentPage, setCurrentPage] = useState(() =>
    preserveState ? parseInt(localStorage.getItem('currentPage') || '1') : 1
  );

  console.log("businessss ordersss...", ordersList);
  
 
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [leadTemplates, setLeadTemplates] = useState<any[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const business = useSelector((state: RootState) => state.auth.business);

  const totalPages = useSelector(
    (state: RootState) => state.ordersList.totalPages
  );
  console.log("Orders:", ordersList.length,ordersList);
  console.log("Total Pages:", totalPages);

  useEffect(() => {
    if (preserveState) {
      localStorage.setItem('orderTime', orderTime);
      localStorage.setItem('selectedStatus', selectedStatus);
      localStorage.setItem('currentPage', currentPage.toString());
    }
  }, [orderTime, selectedStatus, currentPage, preserveState]);

  useEffect(() => {
    dispatch(
      fetchOrderList({
        time: orderTime,
        status: selectedStatus,
        page: currentPage,
      })
    );
  }, [dispatch, orderTime, selectedStatus, currentPage]);

  useEffect(() => {
    if (business) {
      dispatch(fetchLeadList({ business })).then((response: any) => {
        setLeadTemplates(response.payload);
      });
    }
  }, [dispatch, business]);

  const handleTimeChange = (newTime: string) => {
    setOrderTime(newTime);
    if (!preserveState) {
      setCurrentPage(1);
    }
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      if(preserveState){
        localStorage.setItem('scrollPosition', window.scrollY.toString());
      }
    }
  };

  const handleCheckboxChange = (status: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedStatus(status);
    } else {
      setSelectedStatus("all");
    }
  };

  const handleRowClick = (orderId: string) => {
    setSelectedRowId(orderId);
    dispatch(setSelectedOrderId(orderId));
  };
  

  return (
    <div className="flex w-full flex-col bg-white">
      <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-2 sm:pr-2">
        <main className="flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
          <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2">
            <Tabs defaultValue="all">
              <div className="flex items-center">
                <OrderHeaderComponent
                  orderTime={orderTime}
                  handleTimeChange={handleTimeChange}
                  selectedStatus={selectedStatus}
                  handleCheckboxChange={handleCheckboxChange}
                  orderLength={ordersList.length}
                />
              </div>
              <TabsContent value={orderTime}>
                <Card x-chunk="dashboard-05-chunk-3">
                  <CardContent className="p-0">
                    <div className="relative">
                      <Table className="table-fixed">
                        <TableHeader>
                          <TableRow>
                            <TableHead className="w-[5vw]"></TableHead>
                            <TableHead>Customer</TableHead>

                            <TableHead className=" ">
                              Status
                            </TableHead>
                            <TableHead className="hidden md:table-cell">
                              Date
                            </TableHead>
                            <TableHead className="hidden md:table-cell">
                              Products
                            </TableHead>
                            <TableHead className="text-right">
                              Amount (Rs.)
                            </TableHead>
                          </TableRow>
                        </TableHeader>
                      </Table>
                      <ScrollArea className="h-[59vh]">
                        <Table className="table-fixed">
                          <TableBody>
                            {ordersList.length === 0 ? (
                              <>
                                <NoDataComponent />
                              </>
                            ) : (
                              ordersList.map((order, index) => (
                                <OrderTableRowComponent
                                  order={order}
                                  currentPage={currentPage}
                                  index={index}
                                  isSelected={selectedRowId === order._id}
                                  onClick={() => handleRowClick(order._id)}
                                />
                              ))
                            )}
                          </TableBody>
                        </Table>
                      </ScrollArea>
                    </div>
                  </CardContent>
                </Card>
                <div className="flex justify-center mt-4">
                  <TablePaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    preserveState={preserveState}        
                  />
                </div>
              </TabsContent>
            </Tabs>
          </div>
        </main>
      </div>
    </div>
  );
}
