import { Link, useLocation, useNavigate } from "react-router-dom";
import { CheckIcon, CircleUser, Menu } from "lucide-react";
import { Button } from "../../@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../@/components/ui/dropdown-menu";
import { Sheet, SheetContent, SheetTrigger } from "../../@/components/ui/sheet";
import { useEffect, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../@/components/ui/popover";
import { CaretSortIcon } from "@radix-ui/react-icons";
import {
  Command,
  CommandEmpty,
  CommandItem,
  CommandList,
} from "../../@/components/ui/command";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { Bot } from "services/API";
import { logout, setSelectedBotId } from "../../store/authSlice";
import ConfirmationDialog from "./LogoutConfirmationDialog";
import { fetchBotPlugins } from "store/botPluginsSlice";
import NotificationLine from "./NotificationLine";

export default function Dashboard() {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeTab, setActiveTab] = useState<string>("Home");
  const selectedBotId = useSelector((state: RootState) => state.auth.selectedBotId);
  const { plugins} = useSelector((state: RootState) => state.botPlugins);
  const bots = useSelector((state: RootState) => state.auth.bots);
  const isECommerce = useSelector((state: RootState) => state.chat.isECommerce);
  console.log("iiiiiiii",isECommerce);

  const [selectedBot, setSelectedBot] = useState<Bot | null>(null);
  const [open, setOpen] = useState(false);
  const [logOutDialogOpen, setLogoutDialogOpen] = useState(false);
  // const [darkMode, setDarkMode] = useState(false);
  
  function clearChatScrollData() {
    // Clear all chat scroll related localStorage items
    const keys = Object.keys(localStorage);
    keys.forEach(key => {
      if (key.startsWith('chatScroll_') || key.startsWith('chatPage_')) {
        localStorage.removeItem(key);
      }
    });
  }

  useEffect(() => {
    if (selectedBotId) {
      dispatch(fetchBotPlugins(selectedBotId));
    }
  }, [dispatch, selectedBotId]);

  useEffect(() => {
    if (bots) {
      if (!selectedBotId) {
        setSelectedBot(bots);
        dispatch(setSelectedBotId(bots._id));
      } else {
        const bot = selectedBotId === bots._id ? bots : null;
        setSelectedBot(bot);
      }
    }
  }, [bots, selectedBotId, dispatch]);

  const handleBotSelect = (bot: Bot) => {
    setSelectedBot(bot);
    dispatch(setSelectedBotId(bot._id));
    setOpen(false);
  };

  //  const handleTabClick = (tabName: string) => {
  //    setActiveTab(tabName);
  //  };

  const getLinkClass = (path: string) =>
    location.pathname === path
      ? "text-foreground font-bold bg-gradient-to-r from-zinc-500 to-zinc-700 text-white pl-[0.6rem] pr-[0.6rem] pt-[0.3rem] pb-[0.3rem] rounded-[12px] shadow-md transition-all duration-300 ease-in-out"
      : "text-muted-foreground p-1 hover:text-zinc-500 hover:shadow-sm transition-all duration-300 ease-in-out";
  
  
  const handleLogout = () => {
    clearChatScrollData();
    dispatch(logout());
    navigate("/");
    window.location.reload();
  };

  const handleLogoutClick = () => {
    setLogoutDialogOpen(true);
  };

  const handleCancel = () => {
    setLogoutDialogOpen(false);
  };

  const handleConfirmLogout = () => {
    handleLogout();
    setLogoutDialogOpen(false);
  };

  const handleSettingsClick = () => {
    navigate("/privacy-policy");
  };

  // useEffect(() => {
  //   if (darkMode) {
  //     document.documentElement.classList.add("dark");
  //   } else {
  //     document.documentElement.classList.remove("dark");
  //   }
  // }, [darkMode]);
  const isReplyOnActive = plugins.some(
    (plugin) => plugin.plugin_name === 'reply_on_off' && plugin.active === true
  );


  return (
    <div className="flex w-full flex-col z-100">
      {isReplyOnActive && <NotificationLine/>}
      <header className={`fixed ${isReplyOnActive ? "top-2" : "top-0"} left-0 right-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6`}>
        <nav className="hidden z-100 bg-inherit flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
          <Link to="" className="cursor-default">
            Agent Zappy
          </Link>
          <Link to="/chatSection" className={getLinkClass("/chatSection")}>
            Chats
          </Link>
          {/* <Link
            to="#"
            className={getLinkClass("Dashboard")}
            onClick={() => handleTabClick("Dashboard")}
          >
            Dashboard
          </Link> */}
          {isECommerce ? (
            <Link to="/orderSection" className={getLinkClass("/orderSection")}>
              Orders
            </Link>
          ) : (
            <>
              {/* // <Link to="/leadSection" className={getLinkClass("/leadSection")}>
        //   Leads
        // </Link>. */}
            </>
          )}
          {/* <Link
            //to='#'
            to="/leadSection"
            className={getLinkClass("/leadSection")}
          >
            Leads
          </Link> */}
          {/* <Link
            //to='#'
            to="/productSection"
            className={getLinkClass("/productSection")}
          >
            Products
          </Link> */}
          {/* <Link
            //to='#'
            to="/customerSection"
            className={getLinkClass("/customerSection")}
          >
            Customers
          </Link> */}
          {/* <Link
            // to="#"
            to="/analysis"
            className="text-muted-foreground transition-colors hover:text-foreground"
          >
            Analytics
          </Link> */}
          {/* <Link
            to="#"
            //to="/leadSection"
            className={getLinkClass("/leadSection")}
          >
            Lead
          </Link>  */}
        </nav>
        <div className="flex-1 flex items-center justify-between md:hidden">
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="outline" size="icon" className="shrink-0">
                <Menu className="h-5 w-5" />
                <span className="sr-only">Toggle navigation menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="bg-gray-200">
              <nav className="grid gap-7 text-sm font-medium z-100 ">
                <Link
                  to=""
                  className="flex items-center gap-2 text-lg font-semibold"
                >
                  Agent Zappy
                </Link>
                <Link
                  to="/chatSection"
                  className={getLinkClass("/chatSection")}
                >
                  Chats
                </Link>
                {/* <Link to="#" className="hover:text-foreground">
                  Dashboard
                </Link> */}
                {isECommerce ? (
                  <Link
                    to="/orderSection"
                    className={getLinkClass("/orderSection")}
                  >
                    Orders
                  </Link>
                ) : (
                  <>
                    {/* // <Link to="/leadSection" className={getLinkClass("/leadSection")}>
        //   Leads
        // </Link>. */}
                  </>
                )}
                {/* <Link
                  //to='#'
                  to="/productSection"
                  className="text-muted-foreground hover:text-foreground"
                >
                  Products
                </Link> */}
                {/* <Link
                  to='#'
                  //to="/customerSection"
                  className="text-muted-foreground hover:text-foreground"
                >
                  Customers
                </Link> */}
                {/* <Link
                  // to="#"
                  to="/analysis"
                  className="text-muted-foreground hover:text-foreground"
                >
                  Analytics
                </Link> */}
                {/* <Link
                  to="#"
                  //to="/leadSection"
                  className={getLinkClass("/leadSection")}
                >
                  Lead
                </Link> */}
              </nav>
            </SheetContent>
          </Sheet>
        </div>
        <div className="flex ml-auto gap-4 items-center">
          <div>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  aria-label="Select an Agent"
                  className="w-[200px] justify-between"
                >
                  {selectedBot ? selectedBot.name : "Select an Agent"}
                  <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-0">
                <Command>
                  <CommandList>
                    {bots ? (
                      <>
                        <CommandEmpty>No agent found.</CommandEmpty>
                        <CommandItem
                          key={bots._id}
                          onSelect={() => handleBotSelect(bots)}
                          className="text-sm"
                        >
                          {bots.name}
                          <CheckIcon
                            className={`ml-auto h-4 w-4 ${
                              selectedBot && selectedBot._id === bots._id
                                ? "opacity-100"
                                : "opacity-0"
                            }`}
                          />
                        </CommandItem>
                      </>
                    ) : (
                      <CommandEmpty>No agent found.</CommandEmpty>
                    )}
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="secondary"
                size="icon"
                className="rounded-full bg-gray-200 hover:bg-gray-100"
              >
                <CircleUser className="h-5 w-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="bg-white border border-gray-200 shadow-lg rounded-lg cursor-pointer"
            >
              <DropdownMenuLabel className="bg-gray-100 text-gray-900 p-2">
                My Account
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer hover:bg-gray-200 text-gray-700"
                onClick={handleSettingsClick}
              >
                Settings
              </DropdownMenuItem>
              <DropdownMenuItem
                className="cursor-pointer hover:bg-gray-200 text-gray-700"
                onClick={() => window.open("https://wa.link/8yvxyv", "_blank")}
              >
                Support
              </DropdownMenuItem>
              {/* <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer hover:bg-gray-200 text-gray-700"
                onClick={() => setDarkMode(!darkMode)}
              >
                <span
              className={`${
                darkMode ? "translate-x-6" : "translate-x-1"
              } inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-300`}
            />
            <span className="absolute left-1.5 top-1.4 text-gray-500 dark:text-gray-200">
              {darkMode ? (
                <MoonIcon className="w-4 h-4" />
              ) : (
                <SunIcon className="w-4 h-4" />
              )}
            </span>
              </DropdownMenuItem> */}
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer hover:bg-gray-200 text-gray-700"
                onClick={handleLogoutClick}
              >
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <ConfirmationDialog
            open={logOutDialogOpen}
            onCancel={handleCancel}
            onConfirm={handleConfirmLogout}
          />
        </div>
      </header>
    </div>
  );
}
