import { TableCell, TableRow } from "../../../../../@/components/ui/table";
import React from "react";

export default function LeadNoDataComponent() {
  return (
    <TableRow>
      <TableCell colSpan={9} className="text-center py-4">
        No data available
      </TableCell>
    </TableRow>
  );
}
