import React, { useEffect, useState } from "react";
import { Label } from "../../../../../@/components/ui/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../../@/components/ui/card";
import { MdCancel, MdOutlineAddBox } from "react-icons/md";
import { ScrollArea } from "../../../../../@/components/ui/scroll-area";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useMedia } from "react-use";
import { Product, ProductRequest, ProductSearchRequest } from "services/API";
import {
  addDeliveryDetails,
  addOrderDetails,
  addToCart,
  fetchOrderTemplates,
  searchProducts,
} from "store/searchProductSlice";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../.././../../@/components/ui/command";
import { Trash2 } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { Input } from "../../../../../@/components/ui/input";
import { Checkbox } from "../../../../../@/components/ui/checkbox";
import { Textarea } from "../../../../../@/components/ui/textarea";
import { fetchOrders } from "store/SelectedUserOrdersSlice";
import { updateOrderStatus } from "store/OrdersSlice";
import { updateChatStatus } from "store/chatSlice";

interface AddLeadCardProps {
  onCancel: () => void;
}

export default function AddNewOrderCard({ onCancel }: AddLeadCardProps) {
  const dispatch: AppDispatch = useDispatch();

  const business = useSelector((state: RootState) => state.auth.business);
  const userID = useSelector((state: RootState) => state.selectedUser.userId);
  const products = useSelector(
    (state: RootState) => state.searchProduct.products
  );
  const searchStatus = useSelector(
    (state: RootState) => state.searchProduct.status
  );
  const orderTemplates = useSelector(
    (state: RootState) => state.searchProduct.orderTemplates
  );
  // const orderTemplateStatus = useSelector(
  //   (state: RootState) => state.searchProduct.orderTemplateStatus
  // );
  // const orderId = useSelector(
  //   (state: RootState) => state.searchProduct.orderId
  // );
  const chatroomId = useSelector((state: RootState) => state.selectedUser.chatroomId);

  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [productQuantities, setProductQuantities] = useState<{
    [key: string]: number;
  }>({});
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleCheckboxChange = () => {
    setIsConfirmed((prev) => !prev);
  };

  const { toast } = useToast();
  const isMobile = useMedia("(max-width: 640px)");

  useEffect(() => {
    if (business) {
      // Initial search to load all products
      const searchParams: ProductSearchRequest = {
        query: "test",
        filters: {
          productFields: {},
          variantFields: {},
          sortFields: [],
        },
      };
      console.log("product list", business, searchParams);
      dispatch(searchProducts({ business, searchParams }));
    }
  }, [business, dispatch]);

  useEffect(() => {
    if (business) {
      dispatch(fetchOrderTemplates(business));
    }
  }, [business, dispatch]);

  const handleInputChange = (fieldId: string, value: string) => {
    setFormValues((prev) => ({
      ...prev,
      [fieldId]: value,
    }));
  };

  if (!orderTemplates || orderTemplates.length === 0) {
    return null;
  }

  // get the first template
  const template = orderTemplates[0];
  const fields = template?.fields || [];

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    if (business) {
      const searchParams: ProductSearchRequest = {
        query: value,
        filters: {
          productFields: {},
          variantFields: {},
          sortFields: [],
        },
      };
      dispatch(searchProducts({ business, searchParams }));
    }
  };

  const handleSelectProduct = (product: Product) => {
    const isProductSelected = selectedProducts.some(
      (p) => p._id === product._id
    );
    console.log("jjj", isProductSelected);

    if (isProductSelected) {
      toast({
        title: "Product Already Added",
        description: `${product.name} is already in your order list.`,
        className: "bg-red-100 text-red-900 border border-red-200",
      });

      return;
    }

    setSelectedProducts((prev) => [...prev, product]);
    setProductQuantities((prev) => ({
      ...prev,
      [product._id]: 1,
    }));
    setIsFocused(false);
  };

  const updateQuantity = (productId: string, newQuantity: number) => {
    if (newQuantity < 1) return;
    setProductQuantities((prev) => ({
      ...prev,
      [productId]: newQuantity,
    }));
  };

  const handleSaveClick = async () => {
    if (!business || !userID || selectedProducts.length === 0) {
      toast({
        title: "Error",
        description: "Missing required information to create order",
        className: "bg-red-100 text-red-900 border border-red-200",
      });
      return;
    }

    setLoading(true);

    try {
      // Create cart request body
      const cartBody: ProductRequest = {
        productDetails: selectedProducts.map((product) => ({
          sku: product.variants[0].sku,
          quantity: productQuantities[product._id] || 1,
        })),
      };

      // Always add to cart first
      const cartResult = await dispatch(
        addToCart({ business, userID, cartBody })
      ).unwrap();
      const newOrderId = cartResult.cartId;
      console.log("Cart created with orderId:", cartResult.cartId);

      if (isConfirmed) {
        // If confirmed, make additional API calls
        if (!fields || !Array.isArray(fields)) {
          throw new Error("Order template fields are not properly loaded");
        }

        const requiredFields = fields.filter((field) => field.required);
        const missingFields = requiredFields.filter(
          (field) => !formValues[field.id]
        );

        if (missingFields.length > 0) {
          throw new Error(
            `Please fill in required fields: ${missingFields
              .map((f) => f.name)
              .join(", ")}`
          );
        }

        const orderDetails: Record<string, string> = {};
        Object.entries(formValues).forEach(([key, value]) => {
          // Ensure all values are strings
          orderDetails[key] = String(value);
        });

        await dispatch(
          addOrderDetails({
            business,
            userID,
            orderDetails,
          })
        ).unwrap();

        // Add delivery details
        const deliveryData = {
          name: formValues.name || "",
          address: formValues.address || "",
          phone: formValues.phone || "",
          zip_code: formValues.zip_Code || "0",
        };
        await dispatch(
          addDeliveryDetails({
            business,
            userID,
            deliveryData,
          })
        ).unwrap();

        await dispatch(
          updateOrderStatus({
            orderId: newOrderId,
            status: "confirm",
          })
        ).unwrap();
        console.log("Order status updated for orderId:", newOrderId);
        if (chatroomId) {
          dispatch(updateChatStatus({
            chatroomId: chatroomId,
            status: "New Order"
          }));
        }
      } else {
        if (chatroomId) {
          dispatch(updateChatStatus({
            chatroomId: chatroomId,
            status: "Partial Order"
          }));
        }
      }
    
      dispatch(fetchOrders(userID));

      toast({
        title: "Success",
        description: isConfirmed
          ? "Order created and processed successfully"
          : "Products added to cart successfully",
        className: "bg-green-100 text-green-900 border border-green-200",
      });

      setSelectedProducts([]);
      setProductQuantities({});
      setFormValues({});
      onCancel();
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to process the order",
        className: "bg-red-100 text-red-900 border border-red-200",
      });
    } finally {
      setLoading(false);
    }
  };

  const removeProduct = (productId: string) => {
    setSelectedProducts((prev) => prev.filter((p) => p._id !== productId));
    setProductQuantities((prev) => {
      const updated = { ...prev };
      delete updated[productId];
      return updated;
    });
  };

  return (
    <Card
      className={`${
        isMobile ? "h-[90vh]" : "h-[78vh]"
      } p-1 mt-0 mb-2 shadow-sm rounded-lg border border-gray-200`}
    >
      <div className="flex justify-end pr-6 pt-4 rounded-b-lg">
        <button
          className="text-sm font-medium text-gray-600 hover:text-gray-500 transition-colors flex items-center mr-4"
          onClick={onCancel}
        >
          <MdCancel className="mr-2" size={16} />
          Cancel
        </button>
        <button
          className="text-sm font-medium text-gray-600 hover:text-gray-500 transition-colors flex items-center"
          onClick={handleSaveClick}
          disabled={loading || selectedProducts.length === 0}
        >
          <MdOutlineAddBox className="mr-2" size={16} />
          {loading ? "Saving..." : "Add"}
        </button>
      </div>
      <ScrollArea className="h-[70vh]">
        <CardHeader className="pb-4 pl-6 pr-6 pt-2">
          <CardTitle className="text-sm font-bold text-gray-800 capitalize">
            Add New Order
          </CardTitle>
          <CardDescription>
            Please fill in the details for the order.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-5 px-6">
          <div>
            <div className="text-sm italic underline underline-offset-4 font-semibold text-gray-800 mb-4">
              Product Details
            </div>
            <Label htmlFor="sku" className="text-sm">
              Products
            </Label>
            <div className="space-y-4">
              <div className="mb-4">
                <Command className="rounded-lg border">
                  <CommandInput
                    placeholder="Search products..."
                    value={searchTerm}
                    onValueChange={handleSearchChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setTimeout(() => setIsFocused(false), 200)}
                  />
                  {isFocused && (
                    <CommandList>
                      <CommandEmpty>No products found.</CommandEmpty>
                      <CommandGroup heading="Products">
                        {searchStatus === "loading" && (
                          <CommandItem disabled className="text-center py-4">
                            Loading products...
                          </CommandItem>
                        )}
                        {searchStatus === "failed" && (
                          <CommandItem
                            disabled
                            className="text-center py-4 text-red-500"
                          >
                            Failed to load products
                          </CommandItem>
                        )}
                        {products.map((product) => (
                          <CommandItem
                            key={product._id}
                            onSelect={() => handleSelectProduct(product)}
                          >
                            <span>{product.name} </span>
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  )}
                </Command>
              </div>
              {selectedProducts.map((product) => {
                const firstVariant = product.variants?.[0];
                const currentQuantity = productQuantities[product._id] || 1;

                return (
                  <div
                    key={product._id}
                    className="flex items-center justify-between p-2 border bg-zinc-100 rounded-md border-zinc-300"
                  >
                    <div className="text-xs">
                      <div className="font-medium text-gray-800">
                        {product.name}
                      </div>
                      <div className="text-gray-600">
                        {firstVariant
                          ? `SKU: ${firstVariant.sku} - Rs.${(
                              firstVariant.price / 100
                            ).toFixed(2)}`
                          : "No variant available"}
                      </div>

                      <div className="flex items-center space-x-2 text-gray-600">
                        Quantity:
                        <button
                          className="px-2 py-1 text-gray-700 rounded-md text-xs hover:bg-zinc-200"
                          onClick={() =>
                            updateQuantity(product._id, currentQuantity - 1)
                          }
                          disabled={currentQuantity <= 1}
                        >
                          −
                        </button>
                        <span className="text-xs font-semibold">
                          {currentQuantity}
                        </span>
                        <button
                          className="px-2 py-1 text-gray-700 rounded-md text-xs hover:bg-zinc-200"
                          onClick={() =>
                            updateQuantity(product._id, currentQuantity + 1)
                          }
                        >
                          +
                        </button>
                      </div>
                      <div className="text-gray-600 ">
                        Price: Rs.
                        {((firstVariant.price / 100) * currentQuantity).toFixed(
                          2
                        )}
                      </div>
                    </div>

                    <button
                      className="text-red-500 hover:text-red-300 text-xs"
                      onClick={() => removeProduct(product._id)}
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className="items-top flex space-x-2 pt-3 pb-3">
              <Checkbox id="terms1" onCheckedChange={handleCheckboxChange} />
              <div className="grid gap-1.5 leading-none">
                <label
                  htmlFor="terms1"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Confirm Order Submission.
                </label>
                <p className="text-xs text-muted-foreground">
                  If details are missing, the order will be saved as a{" "}
                  <strong>Partial Order</strong>.
                </p>
              </div>
            </div>
            {isConfirmed && (
              <div>
                <div className="text-sm italic underline underline-offset-4 font-semibold text-gray-800 mb-4 mt-2">
                  Order Details
                </div>
                {Array.isArray(fields) &&
                  fields.map((field) => (
                    <div key={field.id} className="pb-6">
                      <Label
                        htmlFor={field.id}
                        className="text-sm font-medium capitalize"
                      >
                        {field.name}
                        {field.required && (
                          <span className="text-red-500 ml-1">*</span>
                        )}
                      </Label>
                      <Input
                        id={field.id}
                        type={field.type === "string" ? "text" : field.type}
                        value={formValues[field.id] || ""}
                        onChange={(e) =>
                          handleInputChange(field.id, e.target.value)
                        }
                        placeholder={`Enter ${field.name.toLowerCase()}`}
                        required={field.required}
                        className="mt-1 w-full"
                      />
                    </div>
                  ))}
                <div>
                  <div className="text-sm italic underline underline-offset-4 font-semibold text-gray-800 mb-4">
                    Delivery Details
                  </div>
                  <div className="pb-4">
                    <Label htmlFor="name">Name</Label>
                    <Input
                      id="name"
                      className="mt-1 w-full"
                      placeholder="Enter name"
                      value={formValues.name || ""}
                      onChange={(e) =>
                        handleInputChange("name", e.target.value)
                      }
                    />
                  </div>
                  <div className="pb-4">
                    <Label htmlFor="name">Phone Number</Label>
                    <Input
                      id="name"
                      className="mt-1 w-full"
                      placeholder="Enter phone number"
                      value={formValues.phone || ""}
                      onChange={(e) =>
                        handleInputChange("phone", e.target.value)
                      }
                    />
                  </div>
                  <div className="pb-4">
                    <Label htmlFor="address">Address</Label>
                    <Textarea
                      id="address"
                      className="mt-1 w-full"
                      placeholder="Enter address"
                      value={formValues.address || ""}
                      onChange={(e) =>
                        handleInputChange("address", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <Label htmlFor="zipCode">Zip Code</Label>
                    <Input
                      id="zip_code"
                      className="mt-1 w-full"
                      placeholder="Enter zip code"
                      value={formValues.zip_code || ""}
                      onChange={(e) =>
                        handleInputChange("zip_code", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </ScrollArea>
    </Card>
  );
}
