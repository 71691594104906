import { Button } from "../../../../../@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "../../../../../@/components/ui/dropdown-menu"; 
import { PlusCircledIcon } from "@radix-ui/react-icons"; 

interface ActivityDropdownProps {
  onItemClick: (title: string, customField: boolean) => void;
  activityTypes: { title: string; icon: any; custom?: boolean }[];
}

export default function ActivityDropdown({
  onItemClick,
  activityTypes,
}: ActivityDropdownProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="h-10 border rounded-[0.5rem]"
        >
          <PlusCircledIcon className="mr-2 h-4 w-4" />
          Actions
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-32 text-black bg-white border border-gray-200 shadow-lg">
        <DropdownMenuGroup>
          {activityTypes.map(({ title, icon, custom }) => (
            <DropdownMenuItem
              key={title}
              onClick={() => onItemClick(title, !!custom)}
            >
              <img src={icon} alt={title} className="mr-2 h-4 w-4" />
              <span>{title || "Custom"}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
