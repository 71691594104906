import { Badge } from "../../../../../@/components/ui/badge";
import { TableCell, TableRow } from "../../../../../@/components/ui/table";
import React, { useState } from "react";

interface OrderTableRowProps {
  order: any;
  currentPage: number;
  index: number;
  isSelected: boolean;
  onClick: () => void;
}

const getBadgeColor = (activity: string) => {
  switch (activity) {
    case "created":
      return "ring-1 ring-blue-500 text-blue-500 ";
    case "confirm":
      return "ring-1 ring-purple-500 text-purple-500 ";
    case "confirmed":
      return "ring-1 ring-green-500 text-green-500";
    case "cancelled":
      return "ring-1 ring-red-500 text-red-500";
    case "dispatched":
      return "ring-1 ring-orange-500 text-orange-500";
    default:
      return "ring-1 ring-gray-300 text-gray-300";
  }
};

const formatUser = (userId: string) => {
  return userId.replace("@c.us", ""); 
};


export default function OrderTableRowComponent({
  order,
  currentPage,
  index,
  isSelected,
  onClick,
}: OrderTableRowProps) {
  const statusMap: Record<string, string> = {
    created: "Partial Order",
    confirm: "New Order",
    confirmed: "Confirmed",
    cancelled: "Cancelled",
    dispatched: "Dispatched",
  };
  const [itemsPerPage] = useState(10);
  console.log("hello order",order)
  
  return (
    <>
      <TableRow
        key={order._id}
        onClick={onClick}
        className={`cursor-pointer transition-colors ${
          isSelected ? "bg-gray-200" : "hover:bg-gray-50"
        }`}
      >
        <TableCell className="w-[5vw] ">
          {(currentPage - 1) * itemsPerPage + index + 1}
        </TableCell>
        <TableCell className="pt-2 pb-2">
          <div className="font-medium capitalize ">
            {order.billing_details?.name || "Unknown User"}
          </div>
          <div className="hidden text-sm text-muted-foreground md:inline">
          {order.billing_details?.phone || (order.user?.length > 0 ? formatUser(order.user[0]) : "Phone Number Not Specified")}
          </div>
        </TableCell>

        <TableCell className="sm:table-cell">
          <Badge
            className={`text-xs ${getBadgeColor(
              order.status[order.status.length - 1]?.activity
            )}`}
            variant="secondary"
          >
            {order.status.length > 0
              ? statusMap[order.status[order.status.length - 1].activity] ||
                "Unknown Status"
              : "No Status"}
          </Badge>
        </TableCell>

        <TableCell className="hidden md:table-cell">
          {order.status.length > 0
            ? new Date(
                order.status[order.status.length - 1].timestamp
              ).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            : "No Date"}
        </TableCell>
        <TableCell className="hidden md:table-cell">
          {order.product_details.length > 0
            ? order.product_details
                .map((product: any) => product.name)
                .join(", ")
            : "No Products"}
        </TableCell>

        <TableCell className="text-right">
          {(order.sale_price / 100).toFixed(2)}
        </TableCell>
      </TableRow>
    </>
  );
}
