import React, { useEffect, useState } from "react";
import { Label } from "../../../../../@/components/ui/label";
import { Input } from "../../../../../@/components/ui/input";
import { fetchLeadList } from "store/leadTemplate";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../@/components/ui/select";
import { LeadFieldItem } from "services/API";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../../@/components/ui/card";
import { MdCancel, MdOutlineAddBox } from "react-icons/md";
import { ScrollArea } from "../../../../../@/components/ui/scroll-area";
import {
  fetchLeadData,
  updateLeadData,
} from "store/SelectedUserLeadTemplateSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useToast } from "../../../../../@/hooks/use-toast";
import { useMedia } from "react-use";
import { updateChatStatus } from "store/chatSlice";

// interface DialogComponentProps {
//   isOpen: boolean;
//   onClose: () => void;
// }

interface AddLeadCardProps {
  onCancel: () => void;
}

export default function AddNewLeadCard({ onCancel }: AddLeadCardProps) {
  const dispatch: AppDispatch = useDispatch();
  const { toast } = useToast();
  const business = useSelector((state: RootState) => state.auth.business);
  const userId = useSelector((state: RootState) => state.selectedUser.userId);
  const chatroomId = useSelector((state: RootState) => state.selectedUser.chatroomId);

  const isMobile = useMedia("(max-width: 640px)");

  console.log("Business in NoLeadMessage:", business);
  console.log("UserId in NoLeadMessage:", userId);
  const [leadTemplates, setLeadTemplates] = useState<any[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [fieldsData, setFieldsData] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  useEffect(() => {
    if (business) {
      dispatch(fetchLeadList({ business })).then((response: any) => {
        console.log("add leads", response.payload);
        setLeadTemplates(response.payload);
      });
    }
  }, [dispatch, business]);

  useEffect(() => {
    if (selectedTemplateId) {
      const selectedTemplate = leadTemplates.find(
        (template) => template.lead_template_id === selectedTemplateId
      );

      if (selectedTemplate) {
        const initialFieldsData: Record<string, string> = {};
        selectedTemplate.fields.forEach((field: LeadFieldItem) => {
          initialFieldsData[field.id] = "";
        });
        setFieldsData(initialFieldsData);
      }
    }
  }, [selectedTemplateId, leadTemplates]);

  const handleSaveClick = () => {
    if (!validateFields()) {
      toast({
        title: "Validation Error",
        description: "Please fill out all required fields.",
        variant: "destructive",
      });
      return;
    }

    if (!selectedTemplateId || !business || !userId) {
      console.error("Invalid inputs: Ensure all required data is available");
      return;
    }

    const updateData = {
      business_id: business,
      lead_id: selectedTemplateId,
      status: selectedStatus || "",
      data: fieldsData,
    };
    console.log("select status", updateData);
    setLoading(true);
    dispatch(updateLeadData({ userId: userId, updateData }))
      .then(() => {
        
        setLoading(false);
        console.log("he he",selectedStatus)
        if (chatroomId) {
          dispatch(updateChatStatus({
            chatroomId: chatroomId,
            status: selectedStatus || "New" 
          }));
        }
        
        handleDialogClose();
        
        dispatch(fetchLeadData({ userId }));

        toast({
          title: "Saved!",
          description: "The template added successfully.",
        });
      })
      .catch((error) => {
        console.error("Failed to add lead data:", error);
        setLoading(false);
        toast({
          title: "Oh! Something went wrong.",
          description: "Failed to add lead data.",
        });
      });
  };

  const handleInputChange = (fieldId: string, value: string) => {
    setFieldsData((prevData) => ({
      ...prevData,
      [fieldId]: value,
    }));
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldId]: "",
      }));
    }
  };

  const validateFields = () => {
    const currentErrors: Record<string, string> = {};

    if (!selectedTemplateId) {
      currentErrors["template"] = "Please select a template.";
    }

    const selectedTemplate = leadTemplates.find(
      (template) => template.lead_template_id === selectedTemplateId
    );

    if (selectedTemplate) {
      selectedTemplate.fields.forEach((field: LeadFieldItem) => {
        if (field.required && !fieldsData[field.id]) {
          currentErrors[field.id] = `${field.name} is required.`;
        }
      });
    }

    setErrors(currentErrors);

    return Object.keys(currentErrors).length === 0;
  };

  const handleDialogClose = () => {
    setSelectedTemplateId(null);
    setFieldsData({});
    setErrors({});
    setSelectedStatus("");
    onCancel();
  };

  const handleTemplateChange = (templateId: string) => {
    setSelectedTemplateId(templateId);
    setErrors((prevErrors) => ({
      ...prevErrors,
      template: "",
    }));
  };

  const handleStatusChange = (status: string) => {
    setSelectedStatus(status);
  };

  const selectedTemplate = leadTemplates.find(
    (template) => template.lead_template_id === selectedTemplateId
  );

  return (
    <Card
      className={`${
        isMobile ? "h-[90vh]" : "h-[78vh]"
      } p-1 mt-0 mb-6 shadow-sm rounded-lg border border-gray-2001`}
    >
      <div className="flex justify-end pr-6 pt-4 rounded-b-lg">
        <button
          className="text-sm font-medium text-gray-600 hover:text-gray-500 transition-colors flex items-center mr-4"
          onClick={onCancel}
        >
          <MdCancel className="mr-2" size={16} />
          Cancel
        </button>
        <button
          className="text-sm font-medium text-gray-600 hover:text-gray-500 transition-colors flex items-center"
          onClick={handleSaveClick}
          disabled={loading}
        >
          <MdOutlineAddBox className="mr-2" size={16} />
          {loading ? "Saving..." : "Add"}
        </button>
      </div>
      <ScrollArea className=" h-[70vh]">
        <CardHeader className="pb-4  pl-6 pr-6 pt-2   ">
          <CardTitle className="text-sm font-bold text-gray-800 capitalize">
            Add New Template
          </CardTitle>
          <CardDescription>
            Please fill in the details for the template.
          </CardDescription>
        </CardHeader>

        <CardContent className="space-y-4">
          <Label htmlFor="template">Select Template</Label>
          <Select onValueChange={handleTemplateChange}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select a template" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {leadTemplates
                  .filter(
                    (template) =>
                      template.lead_template_name.toLowerCase() !==
            "whatsapp orders".toLowerCase()
                  )
                  .map((template) => (
                    <SelectItem
                      key={template.lead_template_id}
                      value={template.lead_template_id}
                      className="capitalize"
                    >
                      {template.lead_template_name}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>

          {errors["template"] && (
            <p className="text-red-500 text-sm">{errors["template"]}</p>
          )}

          {selectedTemplate && (
            <>
              <div className="space-y-4 mt-4">
                {selectedTemplate.fields.map((field: LeadFieldItem) => (
                  <div key={field.id}>
                    <Label className="capitalize" htmlFor={field.id}>
                      {field.name}
                      {field.required && (
                        <span className="text-red-500"> *</span>
                      )}
                    </Label>
                    <Input
                      id={field.id}
                      className="mt-1 w-full"
                      placeholder={`Enter ${field.name}`}
                      required={field.required}
                      value={fieldsData[field.id] || ""}
                      onChange={(e) =>
                        handleInputChange(field.id, e.target.value)
                      }
                    />
                    {errors[field.id] && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors[field.id]}
                      </p>
                    )}
                  </div>
                ))}
              </div>

              <div className="space-y-4 mt-4">
                <Label>Status List</Label>
                <Select onValueChange={handleStatusChange}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select a status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {selectedTemplate.lead_template_status_list.map(
                        (status: string, index: number) => (
                          <SelectItem key={index} value={status}>
                            {status}
                          </SelectItem>
                        )
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </>
          )}
        </CardContent>
      </ScrollArea>
    </Card>
  );
}
