import { TabsList, TabsTrigger } from "../../../../../@/components/ui/tabs";
import React from "react";
import StatusFilterDropdownComponent from "./LeadStatusFliterDropDownComponent";
import { Button } from "../../../../../@/components/ui/button";
import { File, Loader2 } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { exportOrdersCsv } from "store/OrdersSlice";
import { toast } from "@/hooks/use-toast";

interface LeadHeaderProps {
  orderTime: string;
  handleTimeChange: (time: string) => void;
  selectedStatus: string;
  handleCheckboxChange: (status: string, isChecked: boolean) => void;
  orderLength: number;
}

const statusMap: Record<string, string> = {
  created: "Partial Order",
  confirm: "New Order",
  confirmed: "Confirmed",
  cancelled: "Cancelled",
  dispatched: "Dispatched",
};

export default function LeadHeaderComponent({
  orderTime,
  handleTimeChange,
  selectedStatus,
  handleCheckboxChange,
  orderLength,
}: LeadHeaderProps) {
  const dispatch = useDispatch<AppDispatch>();
  const business = useSelector((state: RootState) => state.auth.business);

  const exportStatus = useSelector(
    (state: RootState) => state.ordersList.exportStatus
  );

  const mappedStatus = statusMap[selectedStatus || "all"] || "All Orders";

  const handleExport = async () => {
    if (!business) {
      console.error("No business ID available");
      return;
    }
    try {
      await dispatch(
        exportOrdersCsv({
          business,
          status: selectedStatus || "all",
          time: orderTime || "all",
        })
      ).unwrap();
      toast({
        title: "Export Successful",
        description: `CSV export started successfully for status: "${
          mappedStatus || "all"
        }" and time: "${orderTime || "all"}".`,
        className: "bg-green-100 border border-green-500 text-green-800",
      });
    } catch (error) {
      console.log("errorsdf", error);
      toast({
        title: "Export Failed",
        description: `Failed to export orders for status: "${
          mappedStatus || "all"
        }" and time: "${orderTime || "all"}". Please try again.`,
        className: "bg-yellow-100 border border-yellow-500 text-yellow-800",
      });
    }
  };

  return (
    <>
      <TabsList>
        {["all", "week", "month", "year"].map((tab) => (
          <TabsTrigger
            key={tab}
            value={tab}
            onClick={() => handleTimeChange(tab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </TabsTrigger>
        ))}
      </TabsList>
      <div className="ml-auto flex items-center gap-2">
        <StatusFilterDropdownComponent
          selectedStatus={selectedStatus}
          handleCheckboxChange={handleCheckboxChange}
        />
        <Button
          size="sm"
          variant="outline"
          className="h-7 gap-1 text-sm"
          onClick={handleExport}
          disabled={orderLength === 0 || exportStatus === "loading"}
        >
          {exportStatus === "loading" ? (
            <Loader2 className="h-3.5 w-3.5 animate-spin" />
          ) : (
            <File className="h-3.5 w-3.5" />
          )}
          <span className="sr-only sm:not-sr-only">Export</span>
        </Button>
      </div>
    </>
  );
}
