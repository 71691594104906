import { Button } from "../../../../../@/components/ui/button";
import { Input } from "../../../../../@/components/ui/input";
import { Label } from "../../../../../@/components/ui/label";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import WhatsappIcon from "../../../../../const/images/logActivity/whatsapp.png";
import CallIcon from "../../../../../const/images/logActivity/call.png";
import EmailIcon from "../../../../../const/images/logActivity/email.png";

interface LogActivityBottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (title: string, description: string) => void;
  title: string;
  description: string;
  setTitle: (value: string) => void;
  setDescription: (value: string) => void;
  isCustomField: boolean;
  loading: boolean;
  titleError?: string;
  descriptionError?: string;
}

export default function LogActivityBottomSheet({
  isOpen,
  onClose,
  onSave,
  title,
  description,
  setTitle,
  setDescription,
  isCustomField,
  loading,
  titleError,
  descriptionError,
}: LogActivityBottomSheetProps) {
  
  const userMobileNumber = useSelector(
    (state: RootState) => state.selectedUser.userMobileNumber
  );
  
  const iconSrc =
  title === "Call"
    ? CallIcon
    : title === "WhatsApp"
    ? WhatsappIcon
    : EmailIcon;

  if (!isOpen) return null;
  const handleSaveClick = () => {
    onSave(title, description);
  };

  return (
    <>
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity"
        onClick={onClose}
      />

      {/* Bottom Sheet */}
      <div className="fixed inset-x-0 bottom-0 z-50 bg-white rounded-t-3xl shadow-xl transform transition-transform duration-300 ease-in-out p-5">
        {/* Header */}
        <div className="flex items-center justify-between pb-4 border-b border-gray-200">
          <button
            onClick={onClose}
            className="text-gray-500 text-sm hover:text-gray-700 transition-colors"
          >
            Close
          </button>
          <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
          <div className="w-6"></div> {/* Placeholder for alignment */}
        </div>

        {/* Form Section */}
        <div className="mt-5">
          {isCustomField && (
            <div>
              <Label htmlFor="title" className="text-sm font-medium text-gray-700">
                Title
              </Label>
              <Input
                id="title"
                className="mt-1 w-full border border-gray-300 rounded-lg shadow-sm text-sm px-3 py-2 focus:ring-2 focus:ring-blue-500"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter a title for the activity"
              />
              {titleError && (
                <p className="text-red-500 text-xs mt-1">{titleError}</p>
              )}
            </div>
          )}

          <div className="mt-4">
            <Label htmlFor="description" className="text-sm font-medium text-gray-700">
              Description
            </Label>
            <Input
              id="description"
              className="mt-1 w-full border border-gray-300 rounded-lg shadow-sm text-sm px-3 py-2 focus:ring-2 focus:ring-gray-500"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter a description"
            />
            {descriptionError && (
              <p className="text-red-500 text-xs mt-1">{descriptionError}</p>
            )}
          </div>
        </div>

        {/* Save Button */}
        <div className="flex justify-end mt-5">
          <Button
            onClick={handleSaveClick}
            disabled={loading}
            className="bg-gray-900 hover:bg-gray-700 text-white px-6 py-2 rounded-lg w-36 transition-all disabled:opacity-60"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </div>

        {/* Contact Info (Only for Call/WhatsApp) */}
{(title === "Call" || title === "WhatsApp") && (
  <>
    <p className="text-xs text-gray-500 mt-5">Contact</p>
    <div 
      className="flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-sm mt-2 cursor-pointer"
      onClick={() => {
        if (title === "Call") {
          window.location.href = `tel:${userMobileNumber}`;
        } else if (title === "WhatsApp") {
          window.location.href = `https://wa.me/${userMobileNumber}`;
        }
      }}
    >
      <div>
        <p className="text-sm font-semibold text-gray-800">{userMobileNumber}</p>
      </div>
      <div className="p-2 bg-white rounded-full shadow">
        <img src={iconSrc} alt={title} className="w-6 h-6 object-contain" />
      </div>
    </div>
  </>
)}

      </div>
    </>
  );
}
