import { AlignRight, User } from "lucide-react";
import { Button } from "../../../../../@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../../../../../@/components/ui/dropdown-menu";
import React, { useState } from "react";
import { Checkbox } from "../../../../../@/components/ui/checkbox";
import { Label } from "../../../../../@/components/ui/label";

interface DesktopFilterProps {
  selectedAdminId: string;
  selectedTemplate: string;
  selectedStatuses: { templateName: string; status: string }[];
  admins: any;
  leadTemplates: any;
  handleTemplateSelection: (templateName: string, templateId: string) => void;
  handleStatusChange: (status: string) => void;
  handleOwnerSelection: (leadOwnerId: string) => void;
}

export default function DesktopFilterComponent({
  selectedAdminId,
  selectedTemplate,
  selectedStatuses,
  admins,
  leadTemplates,
  handleTemplateSelection,
  handleStatusChange,
  handleOwnerSelection,
}: DesktopFilterProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdownOpen = () => {
    setDropdownOpen((prevOpen) => !prevOpen);
  };

  console.log("lll",leadTemplates)

  return (
    <>
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="p-2 hover:bg-gray-100 transition-all"
            onClick={toggleDropdownOpen}
          >
            <AlignRight className="h-5 w-5 text-gray-700" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[18vw]">
          <DropdownMenuLabel>Filter By</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <div className="px-2">
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <User className="mr-2 h-4 w-4" />
                <span>Contact Owner</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent className="pl-6">
                {admins.length === 0 ? (
                  <div className="text-gray-500">
                    No contact owners available.
                  </div>
                ) : (
                  admins.map((admin: any) => (
                    <DropdownMenuItem key={admin._id}>
                      <Checkbox
                        checked={selectedAdminId === admin._id}
                        onCheckedChange={() => handleOwnerSelection(admin._id)}
                        id={admin.name}
                        className="mr-2"
                        onClick={(e) => e.stopPropagation()}
                      />
                      <Label
                        htmlFor={admin.name}
                        className="text-sm font-medium text-gray-700"
                      >
                        {admin.name}
                      </Label>
                    </DropdownMenuItem>
                  ))
                )}
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          </div>

          <DropdownMenuSeparator />
          <div className="px-2">
            {leadTemplates.length === 0 ? (
              <div className="text-gray-500">No lead templates available.</div>
            ) : (
              leadTemplates.map((template: any) => (
                <DropdownMenuSub key={template.lead_template_id}>
                  <DropdownMenuSubTrigger>
                    <Checkbox
                      checked={selectedTemplate === template.lead_template_name}
                      onCheckedChange={() =>
                        handleTemplateSelection(
                          template.lead_template_name,
                          template.lead_template_id
                        )
                      }
                      id={template.lead_template_name}
                      className="mr-2"
                      onClick={(e) => e.stopPropagation()}
                    />
                    <Label
                      htmlFor={template.lead_template_name}
                      className="text-sm font-medium text-gray-700"
                    >
                      {template.lead_template_name}
                    </Label>
                  </DropdownMenuSubTrigger>
                  <DropdownMenuSubContent className="pl-6">
                    {template.lead_template_name === "Whatsapp Orders"
                      ? [
                          "Partial Order",
                          "New Order",
                          "Confirmed",
                          "Cancelled",
                          "Dispatched",
                        ].map((status: string) => (
                          <DropdownMenuItem key={status}>
                            <Checkbox
                              checked={selectedStatuses.some(s => s.templateName === template.lead_template_name && s.status === status)}
                              onCheckedChange={() => handleStatusChange(status)}
                              id={status}
                              className="mr-2"
                              onClick={(e) => e.stopPropagation()}
                            />
                            <Label htmlFor={status} className="text-sm">
                              {status}
                            </Label>
                          </DropdownMenuItem>
                        ))
                      : template.lead_template_status_list.map(
                          (status: string) => (
                            <DropdownMenuItem key={status}>
                              <Checkbox
                                checked={selectedStatuses.some(
                                  (s) =>
                                    s.templateName ===
                                      template.lead_template_name &&
                                    s.status === status
                                )}
                                onCheckedChange={() =>
                                  handleStatusChange(status)
                                }
                                id={status}
                                className="mr-2"
                                onClick={(e) => e.stopPropagation()}
                              />
                              <Label htmlFor={status} className="text-sm">
                                {status}
                              </Label>
                            </DropdownMenuItem>
                          )
                        )}
                  </DropdownMenuSubContent>
                </DropdownMenuSub>
              ))
            )}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
