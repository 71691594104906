import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { searchProductsApi, Product, ProductSearchRequest, addCartApi, ProductRequest, OrderFieldTemplate, fetchOrderTemplateFields, AddOrderDetailsApi, addDeliveryDetailsApi, OrderData } from "../services/API";

interface ProductState {
  products: Product[];
  orderTemplates: OrderFieldTemplate[];
  orderId: string | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  orderTemplateStatus: "idle" | "loading" | "succeeded" | "failed";
  cartStatus: "idle" | "loading" | "succeeded" | "failed";
  orderStatus: "idle" | "loading" | "succeeded" | "failed";
  deliveryStatus: "idle" | "loading" | "succeeded" | "failed";
}

const initialState: ProductState = {
  products: [],
  orderTemplates: [],
  orderId: null,
  status: "idle",
  orderTemplateStatus: "idle",
  error: null,
  cartStatus: "idle",
  orderStatus: "idle",
  deliveryStatus: "idle",
};

//get the product list with search
export const searchProducts = createAsyncThunk(
  "product/searchProducts",
  async (
    { business,searchParams }: { business: string, searchParams: ProductSearchRequest },
    { getState, rejectWithValue }
  ) => {
    console.log("product business",business)
    if (!business) {
      return rejectWithValue("No business selected");
    }

    try {
      const response = await searchProductsApi(business, searchParams);
      console.log("my products in slice",response)
      return response.data;
    } catch (error: any) {
      console.log("errors in slice",error)
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

//Async thunk for adding product to cart
export const addToCart = createAsyncThunk(
  "product/addToCart",
  async ({ business, userID, cartBody }: { business: string, userID: string, cartBody: ProductRequest }, { rejectWithValue }) => {
    try {
      const response = await addCartApi(business, userID, cartBody);
      console.log("myyy",response.cart._id)
      return { cartId: response.cart._id }; 
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Failed to add to cart");
    }
  }
);

//get the order fields
export const fetchOrderTemplates = createAsyncThunk(
  "product/fetchOrderTemplates",
  async (business: string, { rejectWithValue }) => {
    try {
      const response = await fetchOrderTemplateFields(business);
      return response; 
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch order templates");
    }
  }
);

// Async thunk for adding order details
export const addOrderDetails = createAsyncThunk(
  "product/addOrderDetails",
  async (
    { business, userID, orderDetails }: { business: string; userID: string; orderDetails: Record<string, string> },
    { rejectWithValue }
  ) => {
    try {
      const transformedData: OrderData = {
        order_details: orderDetails
      };
      await AddOrderDetailsApi(business, userID, transformedData );
      return orderDetails;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Failed to add order details");
    }
  }
);

// Async thunk for adding delivery details
export const addDeliveryDetails = createAsyncThunk(
  "product/addDeliveryDetails",
  async (
    { business, userID, deliveryData }: { business: string; userID: string; deliveryData: { name: string; address: string; phone: string; zip_code: string } },
    { rejectWithValue }
  ) => {
    try {
      await addDeliveryDetailsApi(business, userID, deliveryData);
      return deliveryData;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Failed to add delivery details");
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    resetProducts(state) {
      state.products = [];
      state.status = "idle";
      state.error = null;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchProducts.pending, (state) => {
        state.status = "loading";
        state.error = null;
        console.log("Searching products...");
      })
      .addCase(
        searchProducts.fulfilled,
        (state, action: PayloadAction<{ searchResult: Product[] }>) => {
          state.status = "succeeded";
          state.products = action.payload.searchResult;
          console.log("Products fetched:", action.payload.searchResult);
        }
      )
      .addCase(
        searchProducts.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.error = action.payload;
          console.error("Failed to fetch products:", action.payload,action);
        }
      )
      .addCase(addToCart.pending, (state) => {
        state.cartStatus = "loading";
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.cartStatus = "succeeded";
        // state.orderId = action.payload.cartId;
        // console.log("Cart added, orderId set to:", action.payload.cartId);
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.cartStatus = "failed";
        state.error = action.payload as string;
      })
      .addCase(fetchOrderTemplates.pending, (state) => {
        state.orderTemplateStatus = "loading";
      })
      .addCase(fetchOrderTemplates.fulfilled, (state, action: PayloadAction<OrderFieldTemplate[]>) => {
        state.orderTemplateStatus = "succeeded";
        state.orderTemplates = action.payload;
      })
      .addCase(fetchOrderTemplates.rejected, (state, action) => {
        state.orderTemplateStatus = "failed";
        state.error = action.payload as string;
      })
      .addCase(addOrderDetails.pending, (state) => {
        state.orderStatus = "loading";
      })
      .addCase(addOrderDetails.fulfilled, (state) => {
        state.orderStatus = "succeeded";
      })
      .addCase(addOrderDetails.rejected, (state, action) => {
        state.orderStatus = "failed";
        state.error = action.payload as string;
      })
      .addCase(addDeliveryDetails.pending, (state) => {
        state.deliveryStatus = "loading";
      })
      .addCase(addDeliveryDetails.fulfilled, (state) => {
        state.deliveryStatus = "succeeded";
      })
      .addCase(addDeliveryDetails.rejected, (state, action) => {
        state.deliveryStatus = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { resetProducts } = productSlice.actions;

export default productSlice.reducer;