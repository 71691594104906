import React from "react";
import NavBar from "../../navigationBar/NavigationBar";
import LeadDashboardLayout from "./leadComponents/LeadLayOut";

export default function LeadSection() {
  return (
    <>
      <NavBar />
      <div className="flex flex-row bg-muted/40">
        <LeadDashboardLayout/>
      </div>
    </>
  );
}
