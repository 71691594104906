import { throttle } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../../../store/store";
import { fetchChatList } from "../../../../../store/chatSlice";
import { resetMessages, setSelectedChatroom } from "store/messageSlice";
import { setSelectedUserId } from "store/SelectedChatUserSlice";
import ErrorComponent from "../../../errorComponent/ErrorComponent";
import ChatListBoadyLoadingComponent from "./ChatListBodyLoadingComponent";
import EmptyChatListComponent from "./EmptyChatListComponent";
import ChatCardComponent from "./ChatCardComponent";
import LoadingSpinner from "./LoadingSpinner";

interface MyCardProps {
  onChatSelect: () => void;
}

export default function MyCard({ onChatSelect }: MyCardProps) {
  const dispatch: AppDispatch = useDispatch();

  const { chats, status, currentPage, totalPages, searchTerm } = useSelector(
    (state: RootState) => state.chat
  );
  const { selectedTemplateId, selectedStatuses } = useSelector(
    (state: RootState) => state.lead
  );
  const token = useSelector((state: RootState) => state.auth.token);
  //const selectedBotId = useSelector((state: RootState) => state.auth.selectedBotId);
  const selectedAdminId = useSelector(
    (state: RootState) => state.admin.selectedFilterAdminId
  );

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const [isRestoringPosition, setIsRestoringPosition] = useState(false);

  const chatroomId = useSelector((state: RootState) => state.selectedUser.chatroomId);

  const listRef = useRef<HTMLDivElement>(null);
  //const isLoadingMore = useRef(false);
  const isStatusUpdate = useRef(false);
  const initialLoadComplete = useRef(false);

  // Create keys for localStorage
  const getScrollPositionKey = useCallback(() => {
    return `chatScroll_${selectedTemplateId || 'all'}_${selectedStatuses.join('_') || 'all'}_${selectedAdminId || 'all'}`;
  }, [selectedTemplateId, selectedStatuses, selectedAdminId]);

  const getPageNumberKey = useCallback(() => {
    return `chatPage_${selectedTemplateId || 'all'}_${selectedStatuses.join('_') || 'all'}_${selectedAdminId || 'all'}`;
  }, [selectedTemplateId, selectedStatuses, selectedAdminId]);

   // Save scroll position and page number to localStorage
   const saveScrollPosition = useCallback(() => {
    if (listRef.current) {
      const currentPosition = listRef.current.scrollTop;
      setScrollPosition(currentPosition);
      localStorage.setItem(getScrollPositionKey(), currentPosition.toString());
      localStorage.setItem(getPageNumberKey(), currentPage.toString());
    }
  }, [getScrollPositionKey, getPageNumberKey, currentPage]);

  // Restore scroll position after chats update
  useEffect(() => {
    if (listRef.current && scrollPosition > 0 && !isRestoringPosition) {
      if (isStatusUpdate.current) {
        listRef.current.scrollTop = scrollPosition;
        isStatusUpdate.current = false;
      }
    }
  }, [chats, scrollPosition, isRestoringPosition]);

  

  const templateId = selectedTemplateId ?? "all";
  const label = selectedStatuses.length > 0 ? selectedStatuses.join(",") : "all";

  // Initial load and position restoration
  // Initial load and position restoration
useEffect(() => {
  // Only run this on initial mount
  if (!initialLoadComplete.current && token) {
    const savedPage = localStorage.getItem(getPageNumberKey());
    const savedPosition = localStorage.getItem(getScrollPositionKey());
    
    if (savedPage && savedPosition) {
      const targetPage = parseInt(savedPage, 10);
      const targetPosition = parseInt(savedPosition, 10);
      
      if (targetPage > 1) {
        // load multiple pages
        setIsRestoringPosition(true);
        
        // load first page
        dispatch(
          fetchChatList({
            label: label,
            limit: 20,
            page: 1,
            templateId: templateId,
            leadOwnerId: selectedAdminId,
          })
        ).then(() => {
          // Sequential page loading
          const loadPages = async () => {
            for (let currentPage = 2; currentPage <= targetPage; currentPage++) {
              await dispatch(
                fetchChatList({
                  label: label,
                  limit: 20,
                  page: currentPage,
                  templateId: templateId,
                  leadOwnerId: selectedAdminId,
                })
              );
            }
            
            // All pages loaded, now restore position
            if (listRef.current) {
              listRef.current.scrollTop = targetPosition;
              setScrollPosition(targetPosition);
            }
            setIsRestoringPosition(false);
          };
          
          loadPages();
        });
      } else {
        // Just need to restore position after the first page loads
        dispatch(
          fetchChatList({
            label: label,
            limit: 20,
            page: 1,
            templateId: templateId,
            leadOwnerId: selectedAdminId,
          })
        ).then(() => {
          // Restore position immediately after page loads
          if (listRef.current) {
            listRef.current.scrollTop = targetPosition;
            setScrollPosition(targetPosition);
          }
        });
      }
    } else {
      // No saved position, just load first page
      dispatch(
        fetchChatList({
          label: label,
          limit: 20,
          page: 1,
          templateId: templateId,
          leadOwnerId: selectedAdminId,
        })
      );
    }
    
    initialLoadComplete.current = true;
  }
}, [
  token, 
  getPageNumberKey, 
  getScrollPositionKey, 
  dispatch, 
  label, 
  templateId, 
  selectedAdminId
]);

  // const saveScrollPosition = useCallback(() => {
  //   if (listRef.current) {
  //     setScrollPosition(listRef.current.scrollTop);
  //   }
  // }, []);

  // Handle filter changes
  useEffect(() => {
    // Only run on filter changes, not initial mount
    if (initialLoadComplete.current && token) {
      // Reset the initialLoadComplete to false so on next render the restoration logic runs
      initialLoadComplete.current = false;
    }
  }, [templateId, label, selectedAdminId, token]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isStatusUpdate.current = false;
      if (!isRestoringPosition) {
        saveScrollPosition();
      }
    };
  }, [saveScrollPosition, isRestoringPosition]);

  const filteredChats = useMemo(() => {
    return chats.filter(
      (chat) =>
        (chat.userMobileNumber?.toLowerCase() ?? "").includes(
          searchTerm.toLowerCase()
        ) && !chat.userMobileNumber.includes("@g.us")
    );
  }, [chats, searchTerm]);

  const loadMoreChats = useCallback(() => {
    if (currentPage < totalPages && !isLoadingMore && status !== "loading") {
      //isLoadingMore.current = true;
      saveScrollPosition();
      setIsLoadingMore(true);

      dispatch(
        fetchChatList({
          label: label,
          limit: 20,
          page: currentPage + 1,
          templateId: templateId,
          leadOwnerId: selectedAdminId,
        })
      ).finally(() => {
        setIsLoadingMore(false);
      });
    }
  }, [
    currentPage,
    totalPages,
    status,
    dispatch,
    label,
    templateId,
    selectedAdminId,
    saveScrollPosition,
    isLoadingMore,
  ]);

  // Enhanced scroll handler
  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current && !isRestoringPosition) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        
        // Show scroll to top button when scrolled down significantly
        setShowScrollTopButton(scrollTop > 100);
        
        // Save position periodically during scrolling
        if (scrollTop % 50 === 0) {
          saveScrollPosition();
        }
        
        // Load more chats when approaching bottom
        if (scrollTop + clientHeight >= scrollHeight - 500) {
          loadMoreChats();
        }
      }
    };

    const scrollContainer = listRef.current;
    if (scrollContainer) {
      const throttledHandler = throttle(handleScroll, 200);
      scrollContainer.addEventListener("scroll", throttledHandler);
      return () => {
        scrollContainer.removeEventListener("scroll", throttledHandler);
        throttledHandler.cancel();
      };
    }
  }, [loadMoreChats, saveScrollPosition, isRestoringPosition]);

   // Scroll to top handler
   const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      setShowScrollTopButton(false);
      // Clear saved position when manually scrolling to top
      localStorage.setItem(getScrollPositionKey(), '0');
      localStorage.setItem(getPageNumberKey(), '1');
    }
  };

  const handleChatSelection = (
    chatroomId: string,
    userId: string,
    userMobileNumber: string,
    isRead: boolean,
  ) => {
    //setSelectedChatroomId(chatroomId);
    dispatch(resetMessages());
    dispatch(setSelectedChatroom(chatroomId));
    dispatch(setSelectedUserId({ userId, userMobileNumber, chatroomId, isRead }));
    onChatSelect();
  };

  // if (status === "loading" && currentPage === 1) {
  //   return <ChatListBoadyLoadingComponent />;
  // }

  if (status === "failed") {
    return <ErrorComponent />;
  }

  const isInitialLoading =
    status === "loading" && currentPage === 1 && filteredChats.length === 0;

  return (
    <div className="relative">
    <div ref={listRef} className="flex flex-col h-[82vh] overflow-y-auto">
      {isInitialLoading ? (
        <ChatListBoadyLoadingComponent />
      ) : filteredChats.length === 0 ? (
        <EmptyChatListComponent />
      ) : (
        <>
          {filteredChats.map((chat, index) => (
            <ChatCardComponent
              key={chat.chatroomId}
              chat={chat}
              selectedChatroomId={chatroomId}
              onChatClick={handleChatSelection}
            />
          ))}
          {(status === "loading" || isLoadingMore) && <LoadingSpinner />}
        </>
      )}
    </div>
    {showScrollTopButton && (
        <button
          onClick={scrollToTop}
          className="absolute bottom-2 right-5 bg-zinc-800 hover:bg-zinc-700 text-white rounded-full p-3 shadow-md transition-all duration-300 z-10"
          aria-label="Scroll to top"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-5 w-5" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M5 10l7-7m0 0l7 7m-7-7v18" 
            />
          </svg>
        </button>
      )}
    </div>
  );
}
