import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OrderTable from "./OrderTable";
import SelectedOrderDetails from "./SelectedOrderDetails";
import { RootState } from "store/store";
import { useMedia } from "react-use";

export default function OrderLayout() {
  const selectedOrderId = useSelector(
    (state: RootState) => state.ordersList.selectedOrderId
  );

  const isMobile = useMedia('(max-width: 900px)');

  const [scrollPosition, setScrollPosition] = useState(0);
  
  useEffect(() => {
    if (selectedOrderId) {
      setScrollPosition(window.scrollY);
    }
  }, [selectedOrderId]);

  // When returning to order list, restore scroll position
  useEffect(() => {
    if (!selectedOrderId && scrollPosition > 0) {
      requestAnimationFrame(() => {
        window.scrollTo(0, scrollPosition);
      });
    }
  }, [selectedOrderId, scrollPosition]);

  return (
     <div className="h-[90vh] flex w-full">
      {isMobile ? (
        <>
          {selectedOrderId ? (
            <div className="w-full p-1 bg-white">
              <SelectedOrderDetails />
            </div>
          ) : (
            <div className="w-full">
              <OrderTable preserveState={true} />
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={`transition-all duration-300 ease-in-out ${
              selectedOrderId ? "w-3/4" : "w-full"
            }`}
          ><OrderTable preserveState={false} />
          </div>
          {selectedOrderId && (
            <div className="w-1/4 pt-[10vh] bg-white">
              <SelectedOrderDetails />
            </div>
          )}
        </>
      )}
    </div>
  );
}
