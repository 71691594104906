import { useMedia } from "react-use";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../../../../@/components/ui/pagination";
import React, { useEffect, useState } from "react";

interface TablePaginationProps {
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
  preserveState?: boolean;
}

export default function TablePaginationComponent({
  currentPage,
  totalPages,
  handlePageChange,
  preserveState = false,
}: TablePaginationProps) {

  const isMobile = useMedia('(max-width: 640px)'); 

  const [selectedPage, setSelectedPage] = useState<number>(() => {
    if (preserveState) {
      const savedPage = localStorage.getItem('selectedPage');
      return savedPage ? parseInt(savedPage) : currentPage;
    }
    return currentPage;
  });

   // Keep selectedPage in sync with currentPage
   useEffect(() => {
    setSelectedPage(currentPage);
  }, [currentPage]);

  // Update localStorage when selectedPage changes
  useEffect(() => {
    if (preserveState) {
      localStorage.setItem('selectedPage', selectedPage.toString());
    }
  }, [selectedPage, preserveState]);

  const generatePageNumbers = () => {
    const pages: (number | 'ellipsis')[] = [];
    
    if (isMobile) {
      pages.push(1);
      if (selectedPage && selectedPage !== 1 && selectedPage !== totalPages) {
        pages.push('ellipsis');
        pages.push(selectedPage);
        pages.push('ellipsis');
      } else {
        if (totalPages > 2) {
          pages.push('ellipsis');
        }
      }
      if (totalPages > 1) {
        pages.push(totalPages);
      }
    } else {
      pages.push(1); 

      if (currentPage === 1) {
        if (totalPages > 2) {
          pages.push('ellipsis');
        }
      } else if (currentPage === 2) {
        pages.push(2);
        if (totalPages > 3) {
          pages.push('ellipsis');
        }
      } else if (currentPage === totalPages - 1) {
        pages.push('ellipsis');
        pages.push(totalPages - 1);
      } else if (currentPage === totalPages) {
        pages.push('ellipsis');
      } else {
        pages.push('ellipsis');
        pages.push(currentPage);
        pages.push('ellipsis');
      }

      if (totalPages > 1) {
        pages.push(totalPages);
      }
    }

    return pages;
  };

  const handlePageClick = (page: number) => {
    setSelectedPage(page);
    handlePageChange(page);
  };

  return (
    <>
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (currentPage !== 1) {
                  handlePageClick(currentPage - 1);
                }
              }}
              className={`px-1 py-1 ${
                currentPage === 1 ? "pointer-events-none text-gray-400" : ""
              }`}
            />
          </PaginationItem>

          {generatePageNumbers().map((page, index) => (
            <PaginationItem key={index}>
              {page === 'ellipsis' ? (
                <PaginationEllipsis />
              ) : (
                <PaginationLink
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePageClick(page as number);
                  }}
                  className={`w-[2rem] ${
                    currentPage === page 
                      ? "bg-primary text-primary-foreground hover:bg-primary/90 hover:text-primary-foreground"
                      : ""
                  }`}
                >
                  {page}
                </PaginationLink>
              )}
            </PaginationItem>
          ))}

          <PaginationItem>
            <PaginationNext
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (currentPage !== totalPages) {
                  handlePageClick(currentPage + 1);
                }
              }}
              className={`px-1 py-1  ${
                currentPage === totalPages
                  ? "pointer-events-none text-gray-400"
                  : ""
              }`}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </>
  );
}
