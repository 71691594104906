import { ListFilter } from "lucide-react";
import { Button } from "../../../../../@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../../@/components/ui/dropdown-menu";
import React from "react";

const statusMap: Record<string, string> = {
  created: "Partial Order",
  confirm: "New Order",
  confirmed: "Confirmed",
  cancelled: "Cancelled",
  dispatched: "Dispatched",
};

interface LeadStatusDropDownProps {
  selectedStatus: string;
  handleCheckboxChange: (backendStatus: string, checked: boolean) => void;
}

export default function StatusFilterDropdownComponent({
  selectedStatus,
  handleCheckboxChange,
}: LeadStatusDropDownProps) {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="sm" className="h-7 gap-1 text-sm">
            <ListFilter className="h-3.5 w-3.5" />
            <span className="sr-only sm:not-sr-only">Filter</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Filter by Status</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {Object.entries(statusMap).map(([backendStatus, displayStatus]) => (
            <DropdownMenuCheckboxItem
              key={backendStatus}
              checked={selectedStatus === backendStatus}
              onCheckedChange={(checked) =>
                handleCheckboxChange(backendStatus, checked)
              }
            >
              {displayStatus}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
