import { AlignRight } from "lucide-react";
import { Button } from "../../../../../@/components/ui/button";
import React, { useEffect, useRef, useState } from "react";
import { ScrollArea } from "../../../../../@/components/ui/scroll-area";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "../../../../../@/components/ui/dropdown-menu";

interface MobileFilterProps {
  selectedAdminId: string;
  selectedTemplate: string;
  selectedStatuses: { templateName: string; status: string }[];
  admins: any;
  leadTemplates: any;
  handleTemplateSelection: (templateName: string, templateId: string) => void;
  handleStatusChange: (status: string) => void;
  handleOwnerSelection: (leadOwnerId: string) => void;
}

export default function MobileFilterComponent({
  selectedAdminId,
  selectedTemplate,
  selectedStatuses,
  admins,
  leadTemplates,
  handleTemplateSelection,
  handleStatusChange,
  handleOwnerSelection,
}: MobileFilterProps) {
  const [showOwners, setShowOwners] = useState(false);
  const [expandedTemplate, setExpandedTemplate] = useState<string | null>(null);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    };

    updateHeight();

    const resizeObserver = new ResizeObserver(updateHeight);
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [showOwners, expandedTemplate]); 

  const toggleOwnersVisibility = () => setShowOwners((prev) => !prev);

  const handleTemplateClick = (e: React.MouseEvent, templateName: string, templateId: string) => {
    e.preventDefault();
    e.stopPropagation();
    setExpandedTemplate((prev) => (prev === templateName ? null : templateName));
    handleTemplateSelection(templateName, templateId);
  };
  

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="p-2 hover:bg-gray-100 transition-all rounded-full">
            <AlignRight className="h-5 w-7 text-gray-700" />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent 
          align="end" 
          side="top" 
          className="w-[95%] bg-gray-50/95 p-0 shadow-xl rounded-xl border border-gray-200"
        >
          <ScrollArea className={`${contentHeight > window.innerHeight * 0.7 ? 'h-[70vh]' : 'h-auto'} overflow-hidden`}>
            <div ref={contentRef} className="p-2 space-y-4">
              {/* Contact Owner Section */}
              <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                <div 
                  className="flex items-center justify-between px-4 py-3 cursor-pointer border-b border-gray-100"
                  onClick={toggleOwnersVisibility}
                >
                  <h3 className="text-base font-medium text-gray-900">Contact Owner</h3>
                  <span className="text-gray-400">
                    {showOwners ? "▲" : "▼"}
                  </span>
                </div>
                
                {showOwners && (
                  <div className="divide-y divide-gray-100">
                    {admins.length === 0 ? (
                      <div className="px-4 py-3 text-sm text-gray-500">No contact owners available.</div>
                    ) : (
                      admins.map((admin: any) => (
                        <div
                          key={admin._id}
                          className="flex items-center px-4 py-3 cursor-pointer hover:bg-gray-50 transition-colors"
                          onClick={() => handleOwnerSelection(admin._id)}
                        >
                          <div className="w-6 h-6 flex items-center justify-center mr-3">
                            {selectedAdminId === admin._id && (
                              <span className="text-green-500 text-lg">✓</span>
                            )}
                          </div>
                          <span className="text-base text-gray-700 capitalize">
                            {admin.name}
                          </span>
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>

              {/* Lead Templates Section */}
              <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                <div className="px-4 py-3 border-b border-gray-100">
                  <h3 className="text-base font-medium text-gray-900">Lead Templates</h3>
                </div>
                
                {leadTemplates.length === 0 ? (
                  <div className="px-4 py-3 text-sm text-gray-500">No lead templates available.</div>
                ) : (
                  <div className="divide-y divide-gray-100">
                    {leadTemplates.map((template: any) => (
                      <div key={template.lead_template_id}>
                        <div 
                          className="flex items-center justify-between px-4 py-3 cursor-pointer hover:bg-gray-50 transition-colors"
                          onClick={(e) => handleTemplateClick(e, template.lead_template_name, template.lead_template_id)}
                        >
                          <div className="flex items-center">
                            <div className="w-6 h-6 flex items-center justify-center mr-3">
                              {selectedTemplate === template.lead_template_name && (
                                <span className="text-green-500 text-lg">✓</span>
                              )}
                            </div>
                            <span className="text-base text-gray-700 capitalize">
                              {template.lead_template_name}
                            </span>
                          </div>
                          <span className="text-gray-400">
                            {expandedTemplate === template.lead_template_name ? "▲" : "▼"}
                          </span>
                        </div>

                        {expandedTemplate === template.lead_template_name && (
                          <div className="bg-gray-50 border-t border-gray-100">
                            {(template.lead_template_name.toLowerCase() === "whatsapp orders"
                              ? ["Partial Order", "New Order", "Confirmed", "Cancelled", "Dispatched"]
                              : template.lead_template_status_list || []
                            ).map((status: any) => (
                              <div
                                key={status}
                                className="flex items-center px-4 py-3 cursor-pointer hover:bg-gray-100/80 transition-colors border-b border-gray-100 last:border-b-0"
                                onClick={() => handleStatusChange(status)}
                              >
                                <div className="w-6 h-6 flex items-center justify-center mr-3 ml-6">
                                  {selectedStatuses.some(
                                    (s) => s.templateName === template.lead_template_name && s.status === status
                                  ) && (
                                    <span className="text-green-500 text-lg">✓</span>
                                  )}
                                </div>
                                <span className="text-base text-gray-600 capitalize">
                                  {status}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </ScrollArea>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
